
<template>
  <div>
    <PageHeader />
    <b-overlay
      :show="user.isGettingMe"
      rounded
      spinner-small
    >
      <b-card>
        <div class="text-center mt-3">
          <b-avatar
            variant="primary"
            size="4em"
          ></b-avatar>
          <h5 class="mt-2 mb-0">
            {{ userInfo?userInfo.username: 'Username'}}
          </h5>
          <h6 class="text-muted">
            {{adminRole }}
          </h6>
          <!-- <ResetPasswordFormModal
            ref="userResetPassword"
            :loading="user.isResettingPasswordUser"
            @reset-password="onSubmitResetPassword"
          /> -->
        </div>

        <!-- profile  -->
        <br>
        <template v-if="isAgent && $allowPermission('agent:edit.profile')">
          <AgentForms :agent-id="userInfo.agentId"/>
        </template>
        <UserProfileForm v-else/>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'ข้อมูลเอเย่นต์',
  },
  components: {
    UserProfileForm: () => import('../../components/forms/user-profile-form'),
    // ResetPasswordFormModal: () =>
    //   import('../../components/forms/reset-password-form-modal'),
    AgentForms: () => import('./agent/forms.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAgent','userInfo', 'adminRole']),
  },
  created() {
    this.getMe()
  },
  methods: {
    ...mapActions(['getMe', 'resetPasswordUser']),
    async onSubmitResetPassword(form) {
      await this.resetPasswordUser(form)
      this.$refs.assistantResetPassword.onClose()
    },
  },
}
</script>
